import React from "react";
import {Heading, Link, List, ListItem, Text} from "@chakra-ui/react";

export const BlogPost2 = () => {
    return (
        <>
            <Heading as="h1" size="xl" mb="4">Protecting Your Trees from Smithtown's Harsh Weather</Heading>
            <Text fontSize="lg" my="4">
                In Smithtown, our trees face the brunt of diverse weather conditions. From the blistering heat of summer
                to the icy grip of winter, each season brings its challenges. As tree lovers and caretakers, it's our
                job to ensure our trees are prepared and protected to thrive year-round.
            </Text>
            <Text fontSize="lg" my="4">
                Let's explore some effective strategies for safeguarding your trees against Smithtown's weather
                extremes, ensuring they remain a vital part of our beautiful community landscape.
            </Text>
            <Heading as="h2" size="lg" mb="4">Seasonal Tree Care Tips</Heading>
            <List spacing={3} my="4">
                <ListItem>Winter Prep: Wrapping young trees to protect them from frost and ensuring proper mulching to
                    retain soil warmth.</ListItem>
                <ListItem>Summer Care: Adequate watering during dry spells and mulching to preserve soil
                    moisture.</ListItem>
                <ListItem>Storm Readiness: Regular pruning to remove potentially hazardous branches and ensure
                    structural integrity.</ListItem>
            </List>
            <Text fontSize="lg" my="4">
                At <Link href="/" color="teal.500">Smithtown Tree Service</Link>, we specialize in preparing and
                maintaining trees for all weather conditions. Our local expertise enables us to provide tailored care
                that meets the specific needs of Smithtown's trees.
            </Text>
            <Text fontSize="lg" my="4">
                For more insights or to get professional help with your tree care needs, visit our <Link
                href="/tree-maintenance" color="teal.500">Tree Maintenance page</Link>. Together, we can keep
                Smithtown's trees healthy, safe, and strong, no matter the season.
            </Text>
        </>
    )
}