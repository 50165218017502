import React from "react";
import johnsoncitymeta from '../assets/images/johnsoncitymeta.jpg'
import bgImage from '../assets/images/overlandpark5.jpg'

import {Box, Button, Flex, Heading, Hide, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";
import {BiConversation, BiCut} from "react-icons/bi";
import {GiChoppedSkull, GiLandMine, GiStumpRegrowth, GiTreeBranch, GiTreeDoor, GiTreeRoots} from "react-icons/gi";
import {FaConciergeBell, FaRegHandScissors, FaRegHandshake, FaTree} from "react-icons/fa";
import {MdEmergencyShare, MdHealthAndSafety, MdOutlineEmergency, MdOutlineNaturePeople} from "react-icons/md";
import {RiEmotionHappyLine} from "react-icons/ri";
import {AiOutlineClockCircle, AiOutlineScissor} from "react-icons/ai";

const phoneNumber = "(631) 888-5967";
const telLink = "tel:6318885967";
const phoneNumberWithDashes = "631-888-5967";

export const SiteData = {
    hoursOfOperation: "24/7",
    "city": "Smithtown, NY",
    phoneNumber,
    phoneNumberWithDashes,
    telLink,
    "buttonCtaText": () => <Flex>Ready for Expert Tree Care? Schedule Your <Text textDecoration={'underline'}
                                                                                 display={'inline'}>Free Consultation
        Today!</Text></Flex>,
    "keywords": "Tree Care, services, professional, affordable, experienced, trimming, Smithtown, NY.",
    "footerText": "Smithtown Tree Service, All rights reserved.",
    "ogImage": johnsoncitymeta,
    "navLinks": [
        {
            "title": 'Home',
            "href": '/'
        },
        {
            "title": 'Services',
            "href": '/services/'
        },
        {
            "title": 'Blog',
            "href": '/blog/'
        },
        {
            "title": 'About',
            "href": '/about/'
        },
        {
            "title": 'Contact Us',
            "href": '/contact/'
        },
        {
            "title": 'Careers',
            "href": '/careers/',
            "hideFromTopNav": true
        },
    ],
    "signupForm": {
        "intro": () => <Text>Welcome to Smithtown Tree Service, the leading tree service provider in Smithtown, NY. Our
            team of expert arborists understands the specific botany and landscaping needs of our region. We are excited
            to offer our extensive services to you. For premier tree service, reach out to us at <Link
                href={'tel:6318885967'} textDecoration={'underline'}>(631) 888-5967</Link> today!</Text>,
        "servicesTitle": "Our Services:"
    },
    "homepage": {
        "bgImage": bgImage,
        "url": 'https://www.smithtowntreeservice.com',
        "metaData": {
            "title": 'Smithtown Tree Service: Expert Tree Care in Smithtown, NY | Call (631) 888-5967',
            "description": 'Professional tree care services in Smithtown, NY. Smithtown Tree Service offers expert solutions for tree trimming, removal, and maintenance. Contact us for superior tree care.'
        },
        "h1": 'Professional Tree Care Services in Smithtown, NY',
        "subtitle": 'Your Trusted Partner for Comprehensive Tree Care - Trimming, Removal, Health Consulting, and More',
        "servicesTitle": 'Why Choose Smithtown Tree Service',
        "values": [
            {
                "id": 0,
                "number": "10,000+",
                "name": "Trees Serviced",
                "icon": GiTreeBranch,
            },
            {
                "id": 1,
                "number": "Top Rated",
                "name": "Client Satisfaction",
                "icon": RiEmotionHappyLine,
            },
            {
                "id": 2,
                "number": "24/7",
                "name": "Emergency Response",
                "icon": AiOutlineClockCircle,
            },
            {
                "id": 3,
                "number": "20+ Years",
                "name": "Experience in Tree Care",
                "icon": FaRegHandshake,
            },
        ],
        "services": [
            {
                "id": '0',
                "text": () => <Text><b>Highly Skilled Arborists:</b> Our certified arborists are well-versed in
                    Smithtown’s unique tree species and landscaping needs, offering unparalleled expertise.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Safe and Efficient Tree Services:</b> We adhere to strict safety standards and
                    use advanced technology to ensure a safe and efficient service for every client in Smithtown.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Competitive Pricing:</b> Offering affordable rates without compromising on
                    quality, we provide value-packed tree care services in Smithtown.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Comprehensive Tree Care:</b> From routine maintenance to emergency services, our
                    wide range of services covers all aspects of tree care.</Text>
            },
            {
                "id": '4',
                "text": () => <Text><b>Local Expertise:</b> As a team rooted in Smithtown, NY, we bring a wealth of
                    local experience and knowledge, ensuring top-quality service for our community.</Text>
            }
        ],
        "content": [
            <>
                <>
                    <Heading as="h2" size="md" mb="4">Welcome to Smithtown Tree Service</Heading>
                    <Text fontSize="md">
                        In the heart of Smithtown, NY, lies a dedicated team of arborists and tree care professionals
                        known as Smithtown Tree Service. We are more than just a service provider; we are caretakers of
                        Smithtown's green heritage. With over two decades in the industry, our expertise in managing the
                        diverse tree species of this region is unparalleled. From the majestic oaks to the delicate
                        birches, each tree in Smithtown tells a story, and we are here to preserve it.
                    </Text>

                    <Text fontSize="md">
                        Smithtown, with its unique blend of urban development and natural landscapes, presents both
                        challenges and opportunities in tree care. Our services are tailored to address the specific
                        needs of each tree and each client. We understand that effective tree care is a blend of
                        science, art, and passion. That's why we approach every project with a commitment to deliver
                        results that not only meet but exceed your expectations.
                    </Text>

                    <Heading as="h2" size="md" mb="4">Our Comprehensive Tree Care Services</Heading>
                    <Text fontSize="md">
                        Our range of services spans from routine maintenance to complex arboricultural challenges. We
                        specialize in <Link href="/tree-pruning" color="teal.500">tree pruning and trimming</Link>, a
                        critical service for the health and aesthetics of your trees. Our pruning techniques are
                        designed to enhance the tree's natural shape, promote healthy growth, and ensure safety. But our
                        expertise doesn't stop there; we are also adept at <Link href="/tree-removal" color="teal.500">safe
                        tree removal</Link>, a service that becomes essential when trees pose a risk to property or
                        public safety.
                    </Text>

                    <Text fontSize="md">
                        In Smithtown, where weather conditions can be harsh, our <Link href="/emergency-tree-services"
                                                                                       color="teal.500">emergency tree
                        services</Link> provide peace of mind. Be it a storm, snow, or unexpected tree fall, our rapid
                        response team is equipped to handle emergencies with efficiency and expertise. Safety, both for
                        our team and your property, is our top priority.
                    </Text>

                    <Text fontSize="md">
                        Beyond these services, we also focus on the overall health and well-being of your trees.
                        Our <Link href="/tree-health-consulting" color="teal.500">tree health consulting</Link> involves
                        a detailed assessment of tree health, identifying potential problems, and recommending
                        treatments. From nutrient management to disease prevention, our arborists employ the latest
                        research and best practices to ensure your trees remain robust and vibrant.
                    </Text>

                    <Heading as="h2" size="md" mb="4">Why Choose Smithtown Tree Service</Heading>
                    <Text fontSize="md">
                        Choosing our <a href={'https://www.cranefamilytree.com/'} target={"_blank"} rel={"noreferrer"}>tree
                        service</a> in Smithtown means partnering with a team that values integrity, quality, and
                        environmental stewardship. Our arborists are not only highly trained in the latest tree care
                        techniques but are also deeply committed to preserving the natural beauty and ecological balance
                        of Smithtown. We use eco-friendly practices in all our operations, ensuring that our work
                        benefits both the trees and the environment they thrive in.
                    </Text>

                    <Text fontSize="md">
                        Our services also extend to enhancing the aesthetics of your landscape. A well-maintained tree
                        can significantly improve the curb appeal of your property, and our team knows exactly how to
                        shape and care for your trees to maximize their beauty. Whether you are a homeowner, a business,
                        or a public facility, our tree care solutions are designed to cater to your specific needs and
                        preferences.
                    </Text>

                    <Heading as="h2" size="md" mb="4">Engaging with the Smithtown Community</Heading>
                    <Text fontSize="md">
                        At Smithtown Tree Service, we believe in engaging with the community we serve. We actively
                        participate in local environmental initiatives and contribute to the preservation of Smithtown's
                        natural heritage. Our team is always eager to share their knowledge and passion for trees,
                        offering advice and education to our clients and the community.
                    </Text>

                    <Heading as="h2" size="md" mb="4">Ready to Serve Your Tree Care Needs</Heading>
                    <Text fontSize="md">
                        We invite you to explore our website and learn more about our services. For any inquiries or to
                        schedule a consultation, feel free to contact us at 631-888-5967. At Smithtown Tree Service,
                        your trees are our top priority, and we are committed to providing the best care they deserve.
                    </Text>

                    <Text fontSize="md">
                        Thank you for considering Smithtown Tree Service. We look forward to the opportunity to care for
                        your trees and contribute to the beauty and health of Smithtown's environment.
                    </Text>
                    <Button as={'a'} href={telLink} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                            color={'white'} fontWeight={'bold'}
                            _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                            p={'16px 30px'} borderRadius={0} mt={'2rem'}
                            lineHeight={'26px'}
                            minH={'66px'}
                            width={'100%'}
                            maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for <Hide
                        below='md'>Immediate</Hide> Assistance</Button>
                </>
            </>
        ],
        "footerHeading": "Contact Smithtown Tree Service",
        "footerText": () => <>For top-tier tree services in Smithtown and the surrounding areas, get in touch with us
            today. Call <Link href={'tel:6318885967'} textDecoration={'underline'}>631-888-5967</Link>, or fill out
            our <Link href={'/contact'}>online form</Link> for non-emergency inquiries. Our team is ready to assist with
            all your tree care needs.</>,
        "footerText2": "Your Trees' Health and Beauty is Our Priority",
        "servicesHeader": "Explore Our Extensive Range of Tree Services",
        "servicesSubtitle": () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Discover our <Link
            href={'/services'}>full suite of services</Link> – from tree health maintenance to emergency removal,
            Smithtown Tree Service is your comprehensive solution for all tree-related needs.</Text>,
        "featuredServices": [
            {
                "name": 'Tree Trimming',
                "description": 'Our tree trimming service in Smithtown is designed to promote the health and aesthetics of your trees. Our specialists are proficient in techniques that enhance structure and beauty specific to each tree species.',
                "icon": BiCut,
                "path": '/tree-trimming/',
                "linkTitle": 'Customized Tree Trimming'
            },
            {
                "name": 'Stump Grinding',
                "description": 'We offer stump grinding services in Smithtown that effectively remove tree stumps, leaving your landscape clean and prepared for new growth or development projects.',
                "icon": GiTreeRoots,
                "path": '/stump-grinding/',
                "linkTitle": 'Efficient Stump Grinding'
            },
            {
                "name": 'Tree Maintenance',
                "description": 'Our tree maintenance services in Smithtown include health evaluations, pruning, and disease control, ensuring your trees remain healthy and vibrant all year round.',
                "icon": FaTree,
                "path": '/tree-health-consulting/',
                "linkTitle": 'Comprehensive Tree Care'
            },
            {
                "name": 'Tree Planting and Relocation',
                "description": 'Whether you’re introducing new trees to your landscape or need to relocate existing ones, our planting and transplanting services ensure the success and health of your trees in their new settings.',
                "icon": GiTreeDoor,
                "path": '/tree-planting/',
                "linkTitle": 'Expert Tree Planting and Relocation'
            },
            {
                "name": 'Tree Health Consulting',
                "description": 'Our certified arborists in Smithtown provide expert tree health consultations, offering insights and solutions for tree care, disease management, and overall maintenance specific to Smithtown’s unique climate.',
                "icon": FaConciergeBell,
                "path": '/tree-health-consulting/',
                "linkTitle": 'Professional Tree Health Consultation'
            },
            {
                "name": 'Emergency Tree Services',
                "description": 'In case of storms or other emergencies, our team in Smithtown is ready to respond swiftly. We offer dependable emergency tree services to address immediate risks and prevent potential hazards.',
                "icon": MdOutlineEmergency,
                "path": '/emergency-tree-services/',
                "linkTitle": 'Reliable Emergency Tree Services'
            },
            {
                "name": 'Arborist Consultations',
                "description": 'Get valuable advice from our professional arborists in Smithtown. We provide expert consultations on tree health, risk assessment, and management strategies, helping you make informed decisions about your trees.',
                "icon": MdOutlineNaturePeople,
                "path": '/arborist-consultations/',
                "linkTitle": 'Arborist Consultations'
            },
            {
                "name": 'Land Clearing',
                "description": 'Our land clearing services in Smithtown are designed for efficiency and environmental responsibility. Whether for construction or landscaping, we prepare your land with attention to detail and care for the ecosystem.',
                "icon": FaRegHandScissors,
                "path": '/land-clearing/',
                "linkTitle": 'Eco-friendly Land Clearing'
            }
        ]
    },
    "careers": {
        "url": "https://www.smithtowntreeservice.com/careers",
        "metaData": {
            "title": "Career Opportunities at Smithtown Tree Service, Smithtown, NY",
            "description": "Join our team at Smithtown Tree Service and build your career in arboriculture. We're looking for passionate individuals dedicated to quality tree care."
        },
        "h1": "Join Our Dynamic Team at Smithtown Tree Service",
        "h2": [
            "Why Work with Smithtown Tree Service?",
            "Explore Our Current Job Openings",
            "Our Commitment to Employee Growth"
        ],
        "headingText": {
            "whyJoin": "Smithtown Tree Service is more than just a workplace; it's a place where your passion for trees and the environment is valued. Join our team committed to exceptional tree care services in Smithtown, NY.",
            "openings": "Check out our current job openings for positions ranging from arborists to grounds maintenance staff. We're looking for individuals who are passionate about tree care and eager to make a difference.",
            "commitment": "At Smithtown Tree Service, we believe in nurturing our team's expertise and offering a supportive, dynamic work environment. Your growth and development in the field of arboriculture are integral to our success."
        },
        "content": [
            <Text>Welcome to the Careers section at Smithtown Tree Service, where your career in arboriculture and tree
                care can thrive. We are a family of professionals dedicated to the highest standards in tree services.
                Join us in making a positive impact in Smithtown, NY.</Text>,
            <Text>Smithtown Tree Service values the strength that comes from our people. We seek dedicated individuals
                who share our commitment to top-notch tree care. From experienced arborists to those eager to learn, we
                offer a workplace where your skills are valued and nurtured.</Text>,
            <Text>Our employees enjoy a culture of personal and professional growth. We provide comprehensive training,
                competitive compensation, and the opportunity to work with advanced tree care equipment. We aim to
                create a safe and rewarding workplace for our team.</Text>,
            <Text>As a key part of Smithtown's community, we take pride in serving our neighbors with integrity and
                professionalism. We support our team members with a positive work environment, respectful teamwork, and
                opportunities for career advancement.</Text>,
            <Text>Explore our current job openings at Smithtown Tree Service and find your place in our growing team.
                From skilled tree climbers to customer service representatives, we offer a range of opportunities. Be a
                part of a team that's dedicated to enhancing Smithtown's natural beauty.</Text>
        ]
    },
    "about": {
        "url": "https://www.smithtowntreeservice.com/about",
        "metaData": {
            "title": "About Smithtown Tree Service - Expert Tree Care in Smithtown, NY",
            "description": "Discover the story behind Smithtown Tree Service, a premier tree care provider in Smithtown, NY. Learn about our commitment to excellence, our skilled team, and our dedication to sustainable tree care."
        },
        "h1": "Discover Smithtown Tree Service - Your Tree Care Experts",
        "h2": [
            "Our Journey to Tree Care Excellence",
            "Skilled Arborists at Your Service",
            "Sustainable and Safe Tree Care Practices"
        ],
        "headingText": {
            "commitment": "Smithtown Tree Service has been a cornerstone of tree care in Smithtown, NY, for over two decades. Our commitment to excellence is evident in every project we undertake, from residential gardens to commercial landscapes.",
            "team": "Our team of certified arborists and experienced tree care professionals is passionate about trees. With extensive training and knowledge, we ensure the highest standards of care for your trees.",
            "services": "We offer a wide range of tree care services, including advanced pruning techniques, safe tree removal, detailed health assessments, and responsive emergency services. Each service is performed with a commitment to safety and sustainability."
        },
        "services": [
            {
                "id": "0",
                "title": "Tree Pruning & Trimming",
                "href": "/tree-pruning/",
                "icon": AiOutlineScissor,
                "description": (
                    <Text>
                        Expert pruning and trimming services to maintain the health and appearance of trees, enhancing
                        their structural integrity and aesthetic value.
                    </Text>
                ),
            },
            {
                "id": "1",
                "title": "Safe Tree Removal",
                "href": "/tree-removal/",
                "icon": GiChoppedSkull,
                "description": (
                    <Text>
                        Professional tree removal services for hazardous, diseased, or inconveniently located trees,
                        ensuring safety and compliance with local regulations.
                    </Text>
                ),
            },
            {
                "id": "2",
                "title": "Stump Grinding & Removal",
                "href": "/stump-grinding/",
                "icon": GiStumpRegrowth,
                "description": (
                    <Text>
                        Efficient stump grinding and removal to clear your landscape, reducing safety hazards and paving
                        the way for new growth.
                    </Text>
                ),
            },
            {
                "id": "3",
                "title": "24/7 Emergency Tree Services",
                "href": "/emergency-tree-services/",
                "icon": MdEmergencyShare,
                "description": (
                    <Text>
                        Rapid response to emergency tree situations, including storm damage and fallen trees, to ensure
                        public safety and property protection.
                    </Text>
                ),
            },
            {
                "id": "4",
                "title": "Tree Health Assessments",
                "href": "/tree-health-assessments/",
                "icon": MdHealthAndSafety,
                "description": (
                    <Text>
                        Comprehensive tree health assessments conducted by certified arborists to identify and address
                        any signs of disease, pest infestation, or environmental stress.
                    </Text>
                ),
            },
            {
                "id": "5",
                "title": "Arborist Consultations",
                "href": "/arborist-consultations/",
                "icon": BiConversation,
                "description": (
                    <Text>
                        Professional consultations offering expert advice on tree care, planting strategies, and
                        maintenance plans tailored to individual tree species and landscape requirements.
                    </Text>
                ),
            },
            {
                "id": "6",
                "title": "Land Clearing Services",
                "href": "/land-clearing/",
                "icon": GiLandMine,
                "description": (
                    <Text>
                        Comprehensive land clearing services for construction, landscaping, or property development
                        projects, ensuring efficient and environmentally responsible execution.
                    </Text>
                ),
            }
        ]
    },
    "testimonials": {
        "h1": 'What Our Clients Say',
        "heading": 'Hear from our satisfied customers in Smithtown and learn why Smithtown Tree Service is their top choice for tree care services.',
        "testimonials": [
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a042581f4e29026704f',
                "name": 'Emily R.',
                "title": 'Smithtown Resident',
                "quote": 'Smithtown Tree Service has been taking care of our trees for years. Their attention to detail and commitment to safety is impressive. I highly recommend their services for anyone looking for reliable tree care.'
            },
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a0425d',
                "name": 'Michael B.',
                "title": 'Local Business Owner',
                "quote": 'The professionalism and expertise of Smithtown Tree Service are unmatched. They transformed our property with their exceptional tree trimming and maintenance services. Friendly, knowledgeable, and always willing to go the extra mile.'
            },
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a804d',
                "name": 'Sarah J.',
                "title": 'Homeowner',
                "quote": 'After a severe storm, Smithtown Tree Service was quick to respond and take care of the fallen trees on our property. Their emergency services are top-notch, and they made a stressful situation much easier to handle.'
            }
        ]
    },
    "services": {
        "url": "https://www.smithtowntreeservice.com/services",
        "metaData": {
            "title": "Expert Tree Care Services in Smithtown | Smithtown Tree Service",
            "description": "Discover our full range of professional tree care services in Smithtown. From tree pruning to emergency services, our skilled team delivers with care and precision."
        },
        "h1": "Your Local Experts for Exceptional Tree Services in Smithtown, NY",
        "h2": "The Tree Care Services We Provide",
        "subtext": "At Smithtown Tree Service, our mission is to provide a variety of tree care services that focus on your landscape’s health and aesthetics at competitive rates.",
        "footerHeading": "Ready to Elevate Your Landscape?",
        "footerText": () => <>Reach out to us at <Link href={'tel:6318885967'}>{phoneNumberWithDashes}</Link> or use our
            online contact form for a free quote. Experience the Smithtown Tree Service difference today!</>,
        "content": <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">Smithtown Tree Service: Expert Tree Care in Smithtown, NY</Heading>
                <Text>At Smithtown Tree Service, we're dedicated to offering superior tree care and maintenance services
                    in Smithtown, NY. Whether you need routine pruning, emergency tree removal, or specialized arborist
                    advice, our team of certified professionals is ready to provide safe, efficient, and environmentally
                    responsible services.</Text>

                <Heading as="h3" size="lg">Why Choose Smithtown Tree Service?</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Experienced Arborists:</b> Our certified arborists bring extensive knowledge and
                        skill to every project, ensuring the highest standards of tree care.</Text></ListItem>
                    <ListItem><Text><b>Advanced Tree Care Techniques:</b> We use modern, eco-friendly methods and
                        equipment to provide comprehensive tree care that prioritizes the health of your trees and the
                        environment.</Text></ListItem>
                    <ListItem><Text><b>24/7 Emergency Services:</b> Understanding that tree emergencies can occur at any
                        time, we offer around-the-clock services to address urgent tree care needs.</Text></ListItem>
                    <ListItem><Text><b>Competitive Pricing:</b> We believe in offering high-quality services at fair and
                        transparent prices, delivering value to every client.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Comprehensive Tree Care & Maintenance Services</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Tree Trimming and Pruning:</b> Our precise trimming and pruning services enhance
                        the health and beauty of your trees, contributing to a safer and more appealing
                        landscape.</Text></ListItem>
                    <ListItem><Text><b>Emergency Tree Services:</b> Responding swiftly to emergency situations, we
                        handle storm-damaged trees, fallen limbs, and other urgent scenarios with expertise and
                        care.</Text></ListItem>
                    <ListItem><Text><b>Stump Grinding and Removal:</b> We provide effective stump grinding services to
                        clear your property and facilitate new landscaping opportunities.</Text></ListItem>
                    <ListItem><Text><b>Arborist Consultations:</b> Benefit from our arborist's expert insights on tree
                        health, risk assessment, and proper tree care strategies.</Text></ListItem>
                    <ListItem><Text><b>Land Clearing:</b> Our land clearing services prepare your property for new
                        construction, landscaping, or agricultural use, ensuring an efficient and eco-friendly process.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Tailored Solutions for Your Trees</Heading>
                <Text>We recognize that each tree and property has unique needs. Our customized approach ensures that
                    every aspect of your tree care is handled with attention to detail and tailored to your specific
                    requirements.</Text>

                <Heading as="h3" size="lg">Commitment to Safety and Environmental Care</Heading>
                <Text>At Smithtown Tree Service, your safety and the health of your trees are our top priorities. We're
                    dedicated to providing services that not only meet but exceed industry standards for safety and
                    environmental responsibility.</Text>

                <Heading as="h3" size="lg">Contact Us for Professional Tree Care</Heading>
                <Text>For expert tree care and maintenance services in Smithtown, NY, trust Smithtown Tree Service.
                    Contact us today for professional, reliable, and eco-conscious tree care solutions.</Text>
            </Stack>
        </Box>,
        "services": [
            {
                "id": '0',
                "text": () => <Text><Link href={'/tree-pruning/'}><b>Tree Pruning and Trimming:</b></Link> Essential for
                    maintaining tree health and aesthetics, our pruning services are performed by skilled
                    arborists.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><Link href={'/tree-removal/'}><b>Tree Removal:</b></Link> Safe and efficient removal
                    of trees, especially those posing risks to property or people.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><Link href={'/emergency-tree-services/'}><b>Emergency Tree Services:</b></Link> Fast
                    and reliable care for urgent tree-related issues, available 24/7.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><Link href={'/tree-health-consulting/'}><b>Tree Health Consulting:</b></Link> Expert
                    assessments and advice for maintaining the health and longevity of your trees.</Text>
            },
            {
                "id": '4',
                "text": () => <Text><Link href={'/stump-grinding/'}><b>Stump Grinding:</b></Link> Removal of tree stumps
                    to enhance safety and aesthetics of your landscape.</Text>
            },
            {
                "id": '5',
                "text": () => <Text><Link href={'/land-clearing/'}><b>Land Clearing:</b></Link> Comprehensive clearing
                    services for construction, landscaping, and property development.</Text>
            },
            {
                "id": '6',
                "text": () => <Text><Link href={'/arborist-consultations/'}><b>Arborist
                    Consultations:</b></Link> Professional insights on tree care, risk assessment, and landscape
                    management.</Text>
            },
        ],
        "whyh2": "Why Choose Smithtown Tree Service?",
        "whySubtext": () => <>Our experts at Smithtown Tree Service have been providing unparalleled professional tree
            care services in the community for years. We work in sync with your unique needs to ensure proper handling
            of your trees and property. Reach out to us at <Link href={'tel:6318885967'}
                                                                 textDecoration={'underline'}>{phoneNumberWithDashes}</Link>.</>,
        "whyServices": [
            {
                "id": '0',
                "text": () => <Text><b>Experienced Professionals:</b> Our dedicated team consists of trained
                    professionals with years of expertise in tree care, ready to handle any project, big or
                    small.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Unmatched Service:</b> We prioritize our customers and their needs, ensuring
                    availability throughout the project and working diligently until their satisfaction.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Reliable and Efficient:</b> We have built a strong reputation through years of
                    consistent, top-notch service to our community.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Local Experts:</b> Our team's deep understanding of Smithtown's local botany,
                    topography, and landscape provides our customers with a level of expertise that is unrivaled.</Text>
            }
        ]
    },
    "contact": {
        "url": "https://www.smithtowntreeservice.com/contact",
        "metaData": {
            "title": "Contact Smithtown Tree Service | Your Tree Care Experts",
            "description": "Reach out to Smithtown Tree Service for top-tier tree care services in Smithtown, NY. Our team is ready to assist with all your tree care needs."
        },
        "h1": "Get in Touch with Smithtown Tree Service",
        "subtitle": "Thank you for choosing Smithtown Tree Service. We are a committed tree service company operating in Smithtown, NY, dedicated to delivering expert tree care services to our valued customers.",
        "sectionHeading": "Why Choose Smithtown Tree Service?",
        "signUpDescription": "To contact us online, please fill out the form below. We will respond as soon as possible.",
        "feedbackText": "Your feedback is valuable to us. If you have any questions or concerns, we would be happy to hear from you. Ensuring your satisfaction is our highest priority.",
        "closingText": () => <>For professional tree services in Smithtown, NY, we're here for all your tree care
            needs.</>,
        "valueProps": [
            {
                "title": "Certified Arborists:",
                "description": "Our team of certified arborists has years of experience and expertise in the best tree care techniques. We are dedicated to delivering exceptional service for the well-being and aesthetics of your trees."
            },
            {
                "title": "Comprehensive Tree Services:",
                "description": "From regular tree maintenance to removal and stump grinding, we offer a wide range of services. Our skilled team is equipped to handle all your tree care requirements."
            },
            {
                "title": "Modern Equipment:",
                "description": "We utilize top-quality, advanced equipment to ensure efficient and safe tree care services. Our tools allow us to handle trees of any size and condition."
            },
            {
                "title": "Environmentally Conscious:",
                "description": "We understand the ecological importance of trees and aim to provide sustainable services. We follow best practices to minimize environmental impact during our tree care services."
            },
            {
                "title": "Customer Satisfaction:",
                "description": "At Smithtown Tree Service, exceeding your expectations is our goal. We are committed to providing superior customer service and ensuring a seamless experience from start to finish."
            }
        ]
    },
    "blog": {
        "url": "https://www.smithtowntreeservice.com/blog",
        "metaData": {
            "title": "Smithtown Tree Service: Expert Tips and Advice | Blog",
            "description": "Explore our blog for insightful tips, professional advice, and the latest trends in tree care, tailored for Smithtown, NY."
        },
        "h1": "Smithtown Tree Service Articles and Tips",
        "h2": "Our Blog",
        "heading": "At Smithtown Tree Service, we're committed to providing our clients with the latest information and guidance for maintaining healthy, vibrant landscapes. Stay up-to-date with our expert tips, industry news, and practical advice tailored for Smithtown.",
        "posts": [
            {
                "id": "1",
                "title": "The Art of Tree Pruning in Smithtown",
                "metaTitle": "Tree Pruning Techniques in Smithtown | Smithtown Tree Service Blog",
                "excerpt": "Discover the art and science of tree pruning and how it benefits the trees of Smithtown.",
                "slug": "art-tree-pruning-smithtown",
                "tags": ["Tree Pruning", "Tree Care", "Smithtown"],
                "metaDescription": "Learn effective tree pruning techniques for enhancing the health and appearance of your trees in Smithtown."
            },
            {
                "id": "2",
                "title": "Protecting Your Trees from Smithtown's Harsh Weather",
                "metaTitle": "Weatherproofing Trees in Smithtown | Smithtown Tree Service Blog",
                "excerpt": "Tips for protecting your trees from the harsh weather conditions in Smithtown, NY.",
                "slug": "protecting-trees-smithtown-weather",
                "tags": ["Tree Protection", "Weather", "Smithtown"],
                "metaDescription": "Understand how to safeguard your trees against Smithtown's challenging weather patterns."
            },
            {
                "id": "3",
                "title": "Eco-Friendly Tree Care Solutions in Smithtown",
                "metaTitle": "Sustainable Tree Care in Smithtown | Smithtown Tree Service Blog",
                "excerpt": "Explore eco-friendly and sustainable practices for tree care in Smithtown.",
                "slug": "eco-friendly-tree-care-smithtown",
                "tags": ["Eco-Friendly", "Sustainable", "Tree Care"],
                "metaDescription": "Learn about sustainable and environmentally friendly methods for tree maintenance in Smithtown."
            },
            {
                "id": "4",
                "title": "The Importance of Regular Tree Health Assessments",
                "metaTitle": "Tree Health Assessments in Smithtown | Smithtown Tree Service Blog",
                "excerpt": "Why regular tree health assessments are crucial for maintaining healthy trees in Smithtown.",
                "slug": "importance-tree-health-assessments",
                "tags": ["Tree Health", "Assessments", "Smithtown"],
                "metaDescription": "Discover the significance of regular tree health assessments and how they contribute to the well-being of your trees."
            }
        ]
    },
    "treePruning": {
        "url": "https://www.smithtowntreeservice.com/tree-pruning/",
        "metaData": {
            "title": "Expert Tree Pruning in Smithtown, NY | Smithtown Tree Service",
            "description": "Enhance the health and beauty of your trees with our professional tree pruning services in Smithtown, NY."
        },
        "h1": "Professional Tree Pruning Services in Smithtown, NY",
        "subtitle": "Regular tree pruning is essential for maintaining the health and aesthetic appeal of your trees. Our team at Smithtown Tree Service offers expert pruning services to ensure the vitality of your trees.",
        "body": <Box>
            <Stack spacing={3}>
                <Heading as="h3">Why Prune Your Trees?</Heading>
                <Text>Pruning is vital for the health and safety of your trees, helping to remove dead or diseased
                    branches, promote growth, and enhance the natural beauty of your trees.</Text>

                <Heading as="h3">Our Tree Pruning Approach</Heading>
                <Text>Our experienced arborists use a combination of modern techniques and traditional methods to prune
                    your trees effectively, ensuring they remain healthy and robust.</Text>

                <Heading as="h3">Schedule Your Tree Pruning Service</Heading>
                <Text>Contact us today at <Link href={'tel:6318885967'}>631-888-5967</Link> to schedule a tree pruning
                    service and keep your trees in top condition.</Text>
            </Stack>
        </Box>
    },
    "treeRemoval": {
        "url": "https://www.smithtowntreeservice.com/tree-removal/",
        "metaData": {
            "title": "Safe and Efficient Tree Removal in Smithtown, NY | Smithtown Tree Service",
            "description": "Smithtown Tree Service offers expert tree removal services in Smithtown, NY. Contact us for reliable and efficient tree removal."
        },
        "h1": "Expert Tree Removal Services in Smithtown, NY",
        "subtitle": "Removing a tree can sometimes be necessary for safety, health, or aesthetic reasons. Our team at Smithtown Tree Service specializes in safe and efficient tree removal.",
        "body": <Box>
            <Stack spacing={3}>
                <Heading as="h3">Safe Removal Practices</Heading>
                <Text>We prioritize safety in all our tree removal operations, using state-of-the-art equipment and
                    following strict safety protocols.</Text>

                <Heading as="h3">Why Remove a Tree?</Heading>
                <Text>Trees may need to be removed due to disease, risk of falling, or interference with structures. We
                    assess each situation carefully to determine the best course of action.</Text>

                <Heading as="h3">Contact Us for Tree Removal</Heading>
                <Text>Need a tree removed safely? Contact Smithtown Tree Service at <Link
                    href={'tel:6318885967'}>631-888-5967</Link> for a consultation and safe removal services.</Text>
            </Stack>
        </Box>
    },
    "treeTrimming": {
        "url": "https://www.smithtowntreeservice.com/tree-trimming/",
        "metaData": {
            "title": "Professional Tree Trimming Services in Smithtown, NY | Smithtown Tree Service",
            "description": "Enhance the beauty and health of your trees with our expert tree trimming services in Smithtown, NY."
        },
        "h1": "Tree Trimming Services in Smithtown, NY",
        "subtitle": "At Smithtown Tree Service, we offer professional tree trimming services to maintain the health, safety, and aesthetic appeal of your trees. Our experienced arborists ensure that your trees are beautifully shaped and in perfect health.",
        "body": <Box>
            <Stack spacing={4}>
                <Heading as="h3" fontSize="xl">The Art and Science of Tree Trimming</Heading>
                <Text fontSize="lg">
                    In Smithtown, tree trimming is not just a routine task; it's an art form that combines aesthetic
                    judgment with scientific understanding. Our trained arborists bring years of experience and deep
                    knowledge of tree biology to every trimming job.
                </Text>
                <Text fontSize="lg">
                    Tree trimming serves multiple purposes: it enhances the beauty of your landscape, promotes the
                    health and longevity of your trees, and ensures safety by removing potentially hazardous limbs.
                    Properly trimmed trees are less likely to suffer from disease and pest infestation, and they're
                    better equipped to withstand Smithtown's varying weather conditions.
                </Text>
                <Heading as="h3" fontSize="xl">Customized Trimming for Every Tree</Heading>
                <Text fontSize="lg">
                    Each tree species has unique trimming requirements. Our team is adept at identifying these needs and
                    executing the most suitable trimming technique for each tree, whether it's an ornamental cherry or a
                    mighty oak.
                </Text>
                <Text fontSize="lg">
                    We also consider the tree's role in your overall landscape. A tree that provides shade might need
                    different trimming compared to one that's meant to be a focal point of your garden. Our goal is to
                    achieve the perfect balance between the tree's health and your landscape's aesthetics.
                </Text>
                <Heading as="h3" fontSize="xl">Safety First</Heading>
                <Text fontSize="lg">
                    Safety is paramount in our tree trimming operations. We use state-of-the-art equipment and adhere to
                    strict safety protocols to protect our staff, your property, and your family. Our meticulous
                    approach ensures that every trimming job is done safely and efficiently.
                </Text>
                <Text fontSize="lg">
                    Regular tree trimming is also a key part of preventive care. By removing weak or dead branches, we
                    reduce the risk of property damage or personal injury during storms or high winds.
                </Text>
                <Heading as="h3" fontSize="xl">Your Partner in Tree Care</Heading>
                <Text fontSize="lg">
                    At Smithtown Tree Service, we view ourselves as your partners in tree care. We're committed to
                    helping you maintain the beauty, health, and safety of your trees year-round.
                </Text>
                <Text fontSize="lg">
                    If you're in Smithtown or the surrounding areas, <Link href="/contact/" color="teal.500">contact
                    us</Link> today to schedule a consultation or learn more about our tree trimming services. Let's
                    work together to keep your trees and property looking their best.
                </Text>
            </Stack>
        </Box>
    },
    "stumpGrinding": {
        "url": "https://www.smithtowntreeservice.com/stump-grinding/",
        "metaData": {
            "title": "Efficient Stump Grinding Services in Smithtown, NY | Smithtown Tree Service",
            "description": "Professional stump grinding services in Smithtown, NY, to enhance the safety and appearance of your landscape."
        },
        "h1": "Stump Grinding Services in Smithtown, NY",
        "subtitle": "Stump grinding is a crucial step in the tree removal process, removing unsightly stumps and preventing potential hazards. Our experts at Smithtown Tree Service provide efficient and thorough stump grinding services.",
        "body": <Box>
            <Stack spacing={4}>
                <Heading as="h3" fontSize="xl">The Art and Science of Tree Trimming</Heading>
                <Text fontSize="lg">
                    In Smithtown, tree trimming is not just a routine task; it's an art form that combines aesthetic
                    judgment with scientific understanding. Our trained arborists bring years of experience and deep
                    knowledge of tree biology to every trimming job.
                </Text>
                <Text fontSize="lg">
                    Tree trimming serves multiple purposes: it enhances the beauty of your landscape, promotes the
                    health and longevity of your trees, and ensures safety by removing potentially hazardous limbs.
                    Properly trimmed trees are less likely to suffer from disease and pest infestation, and they're
                    better equipped to withstand Smithtown's varying weather conditions.
                </Text>
                <Heading as="h3" fontSize="xl">Customized Trimming for Every Tree</Heading>
                <Text fontSize="lg">
                    Each tree species has unique trimming requirements. Our team is adept at identifying these needs and
                    executing the most suitable trimming technique for each tree, whether it's an ornamental cherry or a
                    mighty oak.
                </Text>
                <Text fontSize="lg">
                    We also consider the tree's role in your overall landscape. A tree that provides shade might need
                    different trimming compared to one that's meant to be a focal point of your garden. Our goal is to
                    achieve the perfect balance between the tree's health and your landscape's aesthetics.
                </Text>
                <Heading as="h3" fontSize="xl">Safety First</Heading>
                <Text fontSize="lg">
                    Safety is paramount in our tree trimming operations. We use state-of-the-art equipment and adhere to
                    strict safety protocols to protect our staff, your property, and your family. Our meticulous
                    approach ensures that every trimming job is done safely and efficiently.
                </Text>
                <Text fontSize="lg">
                    Regular tree trimming is also a key part of preventive care. By removing weak or dead branches, we
                    reduce the risk of property damage or personal injury during storms or high winds.
                </Text>
                <Heading as="h3" fontSize="xl">Your Partner in Tree Care</Heading>
                <Text fontSize="lg">
                    At Smithtown Tree Service, we view ourselves as your partners in tree care. We're committed to
                    helping you maintain the beauty, health, and safety of your trees year-round.
                </Text>
                <Text fontSize="lg">
                    If you're in Smithtown or the surrounding areas, <Link href="/contact" color="teal.500">contact
                    us</Link> today to schedule a consultation or learn more about our tree trimming services. Let's
                    work together to keep your trees and property looking their best.
                </Text>
            </Stack>
        </Box>
    },
    "treeHealthConsulting": {
        "url": "https://www.smithtowntreeservice.com/tree-health-consulting/",
        "metaData": {
            "title": "Professional Tree Health Consulting in Smithtown, NY | Smithtown Tree Service",
            "description": "Expert tree health consulting services in Smithtown, NY, to ensure the longevity and vitality of your trees."
        },
        "h1": "Comprehensive Tree Health Consulting in Smithtown, NY",
        "subtitle": "Our tree health consulting services are designed to provide you with the insights and recommendations needed for the optimal care and maintenance of your trees.",
        "body": <Box>
            <Stack spacing={4}>
                <Heading as="h3" fontSize="xl">The Importance of Tree Health Consulting</Heading>
                <Text fontSize="lg">
                    Trees are vital to our environment and the beauty of our landscapes. At Smithtown Tree Service, we
                    offer comprehensive tree health consulting services to ensure your trees are healthy, vibrant, and
                    thriving. Our certified arborists are equipped with the knowledge and tools to provide expert advice
                    on tree care.
                </Text>
                <Text fontSize="lg">
                    Whether you're dealing with a specific tree health issue or looking for general maintenance advice,
                    our team can help. We provide in-depth assessments to diagnose problems and create customized care
                    plans for your trees.
                </Text>
                <Heading as="h3" fontSize="xl">What Our Tree Health Consulting Covers</Heading>
                <Text fontSize="lg">
                    Our consulting services cover a wide range of tree health aspects, including disease diagnosis, pest
                    management, soil analysis, and nutritional needs. We also offer guidance on proper pruning
                    techniques, watering schedules, and other care practices to enhance the health and longevity of your
                    trees.
                </Text>
                <Text fontSize="lg">
                    In addition to addressing immediate health concerns, our consulting services focus on preventive
                    care. We help you identify potential issues before they become serious problems, saving you time and
                    money in the long run.
                </Text>
                <Heading as="h3" fontSize="xl">Tailored Advice for Your Trees</Heading>
                <Text fontSize="lg">
                    Every tree is unique, and so are its care requirements. Our arborists take the time to understand
                    your specific situation and provide tailored advice that's right for your trees and landscape. We
                    consider factors like tree species, location, soil conditions, and exposure to environmental
                    stresses.
                </Text>
                <Heading as="h3" fontSize="xl">Partner with Us for Healthy Trees</Heading>
                <Text fontSize="lg">
                    Partner with Smithtown Tree Service for all your tree health needs. Our goal is to ensure that your
                    trees are not just surviving but thriving. Contact us at <Link href={'tel:6318885967'}
                                                                                   color="teal.500">631-888-5967</Link> or
                    visit our <Link href="/contact/" color="teal.500">contact page</Link> to schedule a tree health
                    consultation and learn more about our services.
                </Text>
            </Stack>
        </Box>
    },
    "landClearing": {
        "url": "https://www.smithtowntreeservice.com/land-clearing/",
        "metaData": {
            "title": "Professional Land Clearing Services in Smithtown, NY | Smithtown Tree Service",
            "description": "Efficient and eco-friendly land clearing services in Smithtown, NY, for construction, landscaping, and property development."
        },
        "h1": "Land Clearing Services in Smithtown, NY",
        "subtitle": "Our land clearing services are designed to prepare your property for new construction, landscaping, or development projects, ensuring a clean and safe work area.",
        "body": <Box>
            <Stack spacing={4}>
                <Heading as="h3" fontSize="xl">Comprehensive Land Clearing Solutions</Heading>
                <Text fontSize="lg">
                    Land clearing is a critical first step in many construction and landscaping projects. At Smithtown
                    Tree Service, we offer professional land clearing services to prepare your property for your next
                    big project. Our team utilizes the latest techniques and machinery to clear land efficiently while
                    adhering to environmental guidelines.
                </Text>
                <Text fontSize="lg">
                    Whether you're preparing for residential development, commercial construction, or agricultural use,
                    our land clearing services are designed to meet your specific needs. We handle everything from tree
                    and brush removal to stump grinding and debris disposal.
                </Text>
                <Heading as="h3" fontSize="xl">Eco-Friendly and Sustainable Practices</Heading>
                <Text fontSize="lg">
                    We understand the importance of preserving the natural environment. Our land clearing methods are
                    eco-friendly and aim to minimize the impact on the surrounding ecosystem. We ensure that the cleared
                    land is left in a state that is suitable for future use, whether it's for building, farming, or
                    landscaping.
                </Text>
                <Text fontSize="lg">
                    Our team also provides guidance on how to best utilize the cleared land, considering factors such as
                    soil health, water drainage, and sustainable landscaping practices.
                </Text>
                <Heading as="h3" fontSize="xl">Ready for Your Next Project</Heading>
                <Text fontSize="lg">
                    If you're in Smithtown or the surrounding areas and need professional land clearing services,
                    Smithtown Tree Service is here to help. Contact us today at <Link href={'tel:6318885967'}
                                                                                      color="teal.500">631-888-5967</Link> or
                    visit our <Link href="/contact/" color="teal.500">contact page</Link> to get started. Our team is
                    ready to make your property ready for whatever project you have in mind.
                </Text>
            </Stack>
        </Box>
    },
    "arboristConsulting": {
        "url": "https://www.smithtowntreeservice.com/arborist-consultations/",
        "metaData": {
            "title": "Expert Arborist Consultations in Smithtown, NY | Smithtown Tree Service",
            "description": "Benefit from professional arborist consulting for your trees in Smithtown, NY. Get expert advice on tree care, health, and maintenance."
        },
        "h1": "Arborist Consultation Services in Smithtown, NY",
        "subtitle": "Our certified arborists provide expert consulting services, offering insights and solutions for the health and care of your trees.",
        "body": <Box>
            <Stack spacing={4}>
                <Heading as="h3" fontSize="xl">Expert Arborist Consultations for Every Need</Heading>
                <Text fontSize="lg">
                    At Smithtown Tree Service, we believe that every tree deserves the best care. Our arborist
                    consultation services are designed to provide you with expert advice on all aspects of tree health
                    and maintenance. Whether you're dealing with a specific tree issue or seeking general advice, our
                    certified arborists are here to help.
                </Text>
                <Text fontSize="lg">
                    Our consultations cover a wide range of topics, including disease diagnosis, pest management, tree
                    nutrition, pruning techniques, and much more. We tailor our advice to suit the unique needs of your
                    trees and landscape.
                </Text>
                <Heading as="h3" fontSize="xl">Tailored Solutions for Your Landscape</Heading>
                <Text fontSize="lg">
                    We understand that each landscape is unique, and so are its requirements. Our arborists take the
                    time to assess your specific situation and provide solutions that are not only effective but also
                    sustainable. We focus on promoting the health and longevity of your trees while enhancing the
                    overall beauty of your landscape.
                </Text>
                <Text fontSize="lg">
                    Our team is also well-versed in local environmental conditions and tree species in Smithtown,
                    ensuring that our recommendations are perfectly suited for your area.
                </Text>
                <Heading as="h3" fontSize="xl">Schedule an Arborist Consultation</Heading>
                <Text fontSize="lg">
                    Whether you're a homeowner, business owner, or property manager in Smithtown, our arborist
                    consultation services are just a call away. Contact us at <Link href={'tel:6318885967'}
                                                                                    color="teal.500">631-888-5967</Link> or
                    via our <Link href="/contact" color="teal.500">contact form</Link> to schedule a consultation. Let's
                    work together to keep your trees and landscape in excellent condition.
                </Text>
            </Stack>
        </Box>
    },
    "treePlanting": {
        "url": "https://www.smithtowntreeservice.com/tree-planting/",
        "metaData": {
            "title": "Professional Tree Planting Services in Smithtown, NY | Smithtown Tree Service",
            "description": "Expert tree planting and transplanting services in Smithtown, NY, to ensure healthy growth and sustainability of your trees."
        },
        "h1": "Tree Planting and Transplanting Services in Smithtown, NY",
        "subtitle": "Our tree planting and transplanting services are designed to ensure the health and proper growth of your trees, whether you're adding new greenery to your landscape or relocating existing trees.",
        "body": <Box>
            <Stack spacing={4}>
                <Heading as="h3" fontSize="xl">Enhancing Landscapes with Professional Tree Planting</Heading>
                <Text fontSize="lg">
                    Smithtown Tree Service takes pride in beautifying Smithtown's landscapes with our professional tree
                    planting services. Planting trees is not just about enhancing aesthetics; it's about contributing to
                    the environment and improving the quality of life in our community. Our experts help select the
                    right trees for your property and ensure they are planted correctly for optimal growth and health.
                </Text>
                <Text fontSize="lg">
                    We offer a range of tree planting options, from ornamental trees that add color and beauty to your
                    landscape to shade trees that provide a cool retreat during hot summer days. Our team considers
                    factors like soil condition, sunlight, and the tree's mature size when recommending and planting
                    trees.
                </Text>
                <Heading as="h3" fontSize="xl">Tree Transplanting with Care and Precision</Heading>
                <Text fontSize="lg">
                    In addition to planting new trees, we specialize in tree transplanting. Whether you're redesigning
                    your landscape or need to move a tree to a more suitable location, our team handles the process with
                    care and precision, ensuring the tree's health and survival during and after the move.
                </Text>
                <Text fontSize="lg">
                    Transplanting a tree requires careful planning and execution. We assess the tree's health, prepare
                    the new site, and use safe methods to transport and replant the tree, giving it the best chance to
                    thrive in its new environment.
                </Text>
                <Heading as="h3" fontSize="xl">Start Your Tree Planting Project Today</Heading>
                <Text fontSize="lg">
                    Whether you're adding a single tree or planning a complete landscape renovation, Smithtown Tree
                    Service is here to guide you every step of the way. Contact us at <Link href={'tel:6318885967'}
                                                                                            color="teal.500">631-888-5967</Link> or
                    visit our <Link href="/contact" color="teal.500">contact page</Link> to discuss your tree planting
                    or transplanting needs. Let's work together to enhance the greenery of Smithtown.
                </Text>
            </Stack>
        </Box>
    },
    "emergencyTreeServices": {
        "url": "https://www.smithtowntreeservice.com/emergency-tree-services/",
        "metaData": {
            "title": "24/7 Emergency Tree Services in Smithtown, NY | Smithtown Tree Service",
            "description": "Prompt and reliable emergency tree services in Smithtown, NY. Smithtown Tree Service is ready to respond to your urgent tree care needs."
        },
        "h1": "Emergency Tree Services in Smithtown, NY",
        "subtitle": "When tree emergencies strike, our team at Smithtown Tree Service is ready to respond with speed and efficiency, ensuring the safety of your property and loved ones.",
        "body": <Box>
            <Stack spacing={4}>
                <Heading as="h3" fontSize="xl">Immediate Response for Tree Emergencies</Heading>
                <Text fontSize="lg">
                    Trees can become hazards without warning, especially during storms or other severe weather
                    conditions. Smithtown Tree Service provides swift and reliable emergency tree services to address
                    these situations. Our team is equipped and ready to respond to any tree-related emergency, ensuring
                    the safety of your property and loved ones.
                </Text>
                <Text fontSize="lg">
                    We handle everything from storm-damaged trees and fallen branches to urgent tree removals. Our
                    priority is to quickly and safely resolve the issue, minimizing any potential damage or hazards.
                </Text>
                <Heading as="h3" fontSize="xl">Expertise in Handling Tree Emergencies</Heading>
                <Text fontSize="lg">
                    Our experienced arborists have the expertise to assess and manage emergency situations effectively.
                    We use the latest equipment and techniques to safely address tree emergencies, ensuring quick
                    resolution and peace of mind for our clients.
                </Text>
                <Text fontSize="lg">
                    In addition to immediate emergency response, we also provide post-emergency services such as site
                    cleanup, stump grinding, and advice on restoring or replanting affected areas.
                </Text>
                <Heading as="h3" fontSize="xl">Contact Us for Urgent Tree Care</Heading>
                <Text fontSize="lg">
                    If you're facing a tree emergency in Smithtown or the surrounding areas, don't hesitate to contact
                    Smithtown Tree Service. Call us any time at <Link href={'tel:6318885967'}
                                                                      color="teal.500">631-888-5967</Link> for prompt
                    assistance. Our emergency tree care team is ready to help you when you need it most.
                </Text>
            </Stack>
        </Box>
    },
    "404": {
        "metaData": {
            "title": "Page Not Found - Smithtown Tree Service",
            "description": "The page you're looking for couldn't be found. Smithtown Tree Service offers professional tree care services. Contact us for all your tree care needs."
        },
        "h1": "404 - Page Not Found",
        "subtitle": "Oops! It seems you've discovered a missing branch in our website.",
        "p": "We apologize for the inconvenience. If you're looking for expert tree care services in Smithtown, NY, our team is ready to assist. Please use our menu to navigate to our main pages or contact us directly for help."
    }
}