import React from "react";
import {Heading, Link, List, ListItem, Text} from "@chakra-ui/react";

export const BlogPost3 = () => {
    return (
        <>
            <Heading as="h1" size="xl" mb="4">Eco-Friendly Tree Care Solutions in Smithtown</Heading>
            <Text fontSize="lg" my="4">
                In an age where environmental consciousness is paramount, Smithtown Tree Service is proud to offer
                eco-friendly tree care solutions. Our practices not only ensure the well-being of your trees but also
                contribute to the sustainability of our Smithtown environment.
            </Text>
            <Text fontSize="lg" my="4">
                Discover how our green practices in tree care are making a positive impact on our community and the
                environment.
            </Text>
            <Heading as="h2" size="lg" mb="4">Sustainable Tree Care Practices</Heading>
            <List spacing={3} my="4">
                <ListItem>Organic Mulching: Utilizing organic mulch to enrich soil health without harmful
                    chemicals.</ListItem>
                <ListItem>Water Conservation Techniques: Implementing efficient watering methods to minimize
                    waste.</ListItem>
                <ListItem>Eco-Friendly Pest Control: Employing biological pest control methods to maintain ecological
                    balance.</ListItem>
            </List>
            <Text fontSize="lg" my="4">
                At <Link href="/" color="teal.500">Smithtown Tree Service</Link>, we believe in a future where tree care
                and environmental stewardship go hand in hand. Embracing eco-friendly methods not only benefits your
                trees but also plays a crucial role in preserving Smithtown's natural beauty.
            </Text>
            <Text fontSize="lg" my="4">
                To learn more about our environmentally responsible services, visit our <Link
                href="/sustainable-practices" color="teal.500">Sustainable Practices page</Link>. Let's work together to
                create a greener, healthier Smithtown.
            </Text>
        </>
    )
}