import React from "react";
import {Heading, Link, List, ListItem, Text} from "@chakra-ui/react";

export const BlogPost4 = () => {
    return (
        <>
            <Heading as="h1" size="xl" mb="4">The Importance of Regular Tree Health Assessments</Heading>
            <Text fontSize="lg" my="4">
                In the bustling town of Smithtown, regular tree health assessments are key to maintaining a vibrant
                urban forest. These assessments are crucial for early detection of potential problems, ensuring that our
                trees continue to thrive and beautify our community.
            </Text>
            <Text fontSize="lg" my="4">
                Let's explore why regular health assessments are vital for your trees and how they contribute to the
                overall well-being of our Smithtown landscape.
            </Text>
            <Heading as="h2" size="lg" mb="4">Benefits of Tree Health Assessments</Heading>
            <List spacing={3} my="4">
                <ListItem>Early Disease Detection: Identifying signs of disease early can save a tree and prevent spread
                    to others.</ListItem>
                <ListItem>Structural Integrity: Assessments can reveal structural weaknesses, allowing for preventive
                    measures.</ListItem>
                <ListItem>Longevity of Trees: Regular check-ups contribute to the long-term health and longevity of
                    trees.</ListItem>
            </List>
            <Text fontSize="lg" my="4">
                Smithtown Tree Service's team of certified arborists is skilled in conducting thorough tree health
                assessments. Our goal is to ensure that every tree in Smithtown is a healthy, thriving member of our
                urban ecosystem.
            </Text>
            <Text fontSize="lg" my="4">
                For more information or to schedule a tree health assessment, visit our <Link
                href="/tree-health-assessments" color="teal.500">Tree Health Assessments page</Link>. Let's keep
                Smithtown's trees in top health together.
            </Text>
        </>
    )
}